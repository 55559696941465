.rankings {
	display: flex;
	justify-content: space-between;
	color: var(--text-color);
	width: 100%;
	padding: 0px 20vw;
	box-sizing: border-box;
}

.board-name {
	width: 100%;
	text-align: center;
}

.board-name h1 {
	margin: 0;
	color: var(--text-color);
	font-weight: normal;
	font-size: 40px;
}

.board-name a h1 {
	margin: 0;
	color: var(--text-color);
	font-weight: normal;
	font-size: 40px;
}

.ranking-list {
	width: calc(30rem + 24px);
	overflow: hidden;
	text-overflow: ellipsiss;
	margin-right: 20px;
}

.back-button a {
	text-decoration: none;
	color: var(--text-color);
	display: flex;
	gap: 10px;
	font-size: 18px;
}

.back-button img {
	transform: rotateZ(270deg);
}

.create-ranking-button {
	width: 200px;
	padding: 5px;
	border-radius: 5px;
	background-color: transparent;
	border: 1px solid var(--text-color);
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px 0px;
}

.create-ranking-button img {
	height: 25px;
	width: auto;
	display: block;
}

.ranking-item {
	display: flex;
	align-items: center;
	padding: 2.5px;
	cursor: pointer;
	font-size: 20px;
	color: var(--secondary-text-color);
	font-style: normal;
	text-decoration: none;
	width: 200px;
}

.hover-line {
	width: 0;
	height: 2px;
	background-color: #000;
	transition-duration: 0.4s, 0.4s;
	transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1),
		cubic-bezier(0.075, 0.82, 0.165, 1);
	transition-delay: 0s, 0s;
	transition-property: width, margin;
}

.ranking-item:hover .hover-line,
.ranking-item.active .hover-line {
	width: 24px;
	margin-right: 12px;
}

.ranking-item span {
	flex: 1;
}

.ranking-preview {
	flex: 1;
	display: flex;
	justify-content: center;
}

.ranking-details {
	display: flex;
	flex-direction: column;
	gap: 10px;
	margin-bottom: 20px;
}

.ranking-details img {
	height: 450px;
	width: auto;
	object-fit: contain;
}

.ranking-details div {
	display: grid;
	grid-template-columns: 150px 150px;
	column-gap: 10px;
	width: 100%;
	justify-content: space-between;
}

.ranking-details p {
	font-size: 16px;
	font-weight: normal;
	color: var(--text-color);
	margin: 5px;
	display: flex;
	justify-content: space-around;
	width: 100%;
}

.ranking-details #board-name {
	color: rgba(0, 0, 0, 0.5);
}

@media (max-width: 1400px) {
	.rankings {
		padding: 0px 10vw;
	}
}

@media (max-width: 992px) {
	.rankings {
		padding: 0px 5vw;
	}

	.ranking-details img {
		height: 350px;
	}

	.ranking-details div {
		grid-template-columns: 120px 120px;
		column-gap: 10px;
	}

	.ranking-details p {
		font-size: 12px;
	}
}

@media (max-width: 768px) {
	.ranking-item {
		font-size: 16px;
	}

	.ranking-details img {
		height: 350px;
	}

	.ranking-details div {
		grid-template-columns: 120px 120px;
		column-gap: 10px;
	}

	.ranking-details p {
		font-size: 12px;
	}
}

@media (max-width: 576px) {
	.ranking-item {
		font-size: 14px;
	}

	.ranking-details img {
		height: 200px;
	}

	.ranking-details div {
		grid-template-columns: 60px 60px;
		column-gap: 10px;
	}

	.ranking-details p {
		font-size: 12px;
	}
}
