.tooltip-container {
	position: relative;
	display: inline-block;
}

.tooltip {
	position: fixed;
	z-index: 10000;
	background-color: #000;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 14px;
	white-space: nowrap;
	pointer-events: auto;
	transition: opacity 0.2s ease-in-out;
}
