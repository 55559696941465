.boards {
	display: flex;
	justify-content: space-between;
	color: var(--text-color);
	width: 1000px !important;
}

.board-list {
	width: 200px;
	margin-right: 20px;
}

.board-item {
	display: flex;
	align-items: center;
	padding: 2.5px;
	cursor: pointer;
	font-size: 20px;
	color: var(--text-color);
}

.hover-line {
	width: 0;
	height: 2px;
	background-color: var(--text-color);
	transition-duration: 0.4s, 0.4s;
	transition-timing-function: cubic-bezier(0.075, 0.82, 0.165, 1),
		cubic-bezier(0.075, 0.82, 0.165, 1);
	transition-delay: 0s, 0s;
	transition-property: width, margin;
}

.board-item:hover .hover-line,
.board-item.active .hover-line {
	width: 24px;
	margin-right: 12px;
}

.board-item span {
	flex: 1;
}

.board-preview {
	flex: 1;
	display: flex;
	justify-content: center;
}

.ranking-preview {
	display: flex;
	gap: 20px;
	margin-top: 20px;
}

.ranking-preview-item {
	text-align: center;
}

.ranking-preview-item img {
	height: 450px !important;
	object-fit: cover;
}

.ranking-preview-item .details {
	display: flex;
	justify-content: space-between;
}

.details #board-name {
	color: var(--text-color-secondary);
}

.create-board-button {
	width: 200px;
	padding: 5px;
	border-radius: 5px;
	background-color: transparent;
	border: 1px solid var(--text-color);
	color: var(--text-color);
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 5px 0px;
}

.create-board-button img {
	height: 25px;
	width: auto;
	display: block;
}

@media (max-width: 1200px) {
	.boards {
		width: 800px !important;
	}

	.board-preview-item img {
		height: 350px !important;
	}
}

@media (max-width: 992px) {
	.boards {
		width: 600px !important;
	}

	.board-preview-item img {
		height: 250px !important;
	}
}

@media (max-width: 768px) {
	.boards {
		width: 400px !important;
	}

	.board-preview-item img {
		height: 200px !important;
	}
}

@media (max-width: 576px) {
	.boards {
		width: 300px !important;
	}

	.board-preview-item img {
		height: 150px !important;
	}
}
