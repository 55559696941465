body {
	margin: 0;
	font-family: "GT Planar", -apple-system, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

@font-face {
	font-family: "GT Planar";
	src: url("./fonts/GT-Planar/GT-Planar-Black-Trial.otf") format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar";
	src: url("./fonts/GT-Planar/GT-Planar-Bold-Trial.otf") format("opentype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 15 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-15-Black-Trial.otf")
		format("opentype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 15 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-15-Bold-Trial.otf")
		format("opentype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 15 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-15-Light-Trial.otf")
		format("opentype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 15 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-15-Medium-Trial.otf")
		format("opentype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 15 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-15-Regular-Trial.otf")
		format("opentype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 15 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-15-Thin-Trial.otf")
		format("opentype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 30 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-30-Black-Trial.otf")
		format("opentype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 30 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-30-Bold-Trial.otf")
		format("opentype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 30 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-30-Light-Trial.otf")
		format("opentype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 30 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-30-Medium-Trial.otf")
		format("opentype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 30 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-30-Regular-Trial.otf")
		format("opentype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 30 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-30-Thin-Trial.otf")
		format("opentype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 45 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-45-Black-Trial.otf")
		format("opentype");
	font-weight: 900;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 45 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-45-Bold-Trial.otf")
		format("opentype");
	font-weight: 700;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 45 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-45-Light-Trial.otf")
		format("opentype");
	font-weight: 300;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 45 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-45-Medium-Trial.otf")
		format("opentype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 45 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-45-Regular-Trial.otf")
		format("opentype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar 45 Italic";
	src: url("./fonts/GT-Planar/GT-Planar-Italic-45-Thin-Trial.otf")
		format("opentype");
	font-weight: 100;
	font-style: italic;
}

@font-face {
	font-family: "GT Planar";
	src: url("./fonts/GT-Planar/GT-Planar-Light-Trial.otf") format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar";
	src: url("./fonts/GT-Planar/GT-Planar-Medium-Trial.otf") format("opentype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar";
	src: url("./fonts/GT-Planar/GT-Planar-Regular-Trial.otf") format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 15 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-15-Black-Trial.otf")
		format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 15 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-15-Bold-Trial.otf")
		format("opentype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 15 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-15-Light-Trial.otf")
		format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 15 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-15-Medium-Trial.otf")
		format("opentype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 15 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-15-Regular-Trial.otf")
		format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 15 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-15-Thin-Trial.otf")
		format("opentype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 30 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-30-Black-Trial.otf")
		format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 30 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-30-Bold-Trial.otf")
		format("opentype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 30 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-30-Light-Trial.otf")
		format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 30 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-30-Medium-Trial.otf")
		format("opentype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 30 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-30-Regular-Trial.otf")
		format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 30 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-30-Thin-Trial.otf")
		format("opentype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 45 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-45-Black-Trial.otf")
		format("opentype");
	font-weight: 900;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 45 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-45-Bold-Trial.otf")
		format("opentype");
	font-weight: 700;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 45 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-45-Light-Trial.otf")
		format("opentype");
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 45 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-45-Medium-Trial.otf")
		format("opentype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 45 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-45-Regular-Trial.otf")
		format("opentype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar 45 Retalic";
	src: url("./fonts/GT-Planar/GT-Planar-Retalic-45-Thin-Trial.otf")
		format("opentype");
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: "GT Planar";
	src: url("./fonts/GT-Planar/GT-Planar-Thin-Trial.otf") format("opentype");
	font-weight: 100;
	font-style: normal;
}
