.create {
	width: 600px;
	font-size: 20px;
	color: var(--text-color);
}

.create .back-button {
	margin: 30px 0px;
}

.create form {
	padding-top: 30px;
	display: flex;
	flex-direction: column;
}

.field {
	display: flex;
	width: 100%;
	padding: 20px 0px;
}

.field label {
	width: 100px !important;
	color: var(--text-color);
}

.field input {
	font-family: "GT Planar";
	font-size: 20px;
	border: none;
	border-bottom: 1px solid var(--text-color);
	outline: none;
	width: 100%;
	background-color: var(--bg-color);
	color: var(--text-color);
}

.create form button {
	font-family: "GT Planar";
	font-size: 20px;
	background: none;
	padding: 5px 10px;
	width: min-content;
	border: 2px solid var(--text-color);
	color: var(--text-color);
	outline: none;
	cursor: pointer;
}

.create form button:hover {
	background: var(--hover-bg-color);
}
