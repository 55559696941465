.update {
	width: 1200px;
	display: flex;
	flex-direction: column;
	font-size: 20px;
	color: var(--text-color);
}

.board-name {
	width: 100%;
	text-align: center;
}

.board-name a h1,
.board-name h1 {
	margin: 0;
	color: var(--text-color);
	font-weight: normal;
}

.ranking {
	display: flex;
	flex-direction: column;
	align-items: stretch;
	width: 100%;
}

.choices {
	display: flex;
	justify-content: space-around;
	gap: 20px;
}

.choice {
	text-align: left;
	font-family: "GT Planar";
	font-size: 20px;
	border: none;
	background: none;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	gap: 15px;
	color: var(--text-color);
	position: relative;
	transition: transform 0.3s ease;
}

.choice img:hover {
	transform: translateY(-5px);
}

.choice img.display {
	height: 400px;
	width: 500px;
	border: 0.75rem solid var(--border-color);
	box-shadow: var(--box-shadow);
	object-fit: cover;
	object-position: center;
	cursor: pointer;
	background: var(--img-bg-color);
	transition: all 0.3s ease;
}

.choice img.display.voted {
	animation: pulse 1.5s ease-out;
}

.choice label button {
	background: none;
	border: none;
	cursor: pointer;
	padding: 0px;
	margin: 0;
	font-family: "GT Planar";
	font-size: 18px;
	color: var(--text-color);
}

.choice img.display {
	height: 400px;
	width: 500px;
	border: 0.75rem solid var(--border-color);
	box-shadow: var(--box-shadow);
	object-fit: cover;
	object-position: center;
	cursor: zoom-in;
	background: var(--img-bg-color);
}

.choices label {
	display: flex;
	width: 100%;
	justify-content: space-between;
	pointer-events: auto;
}

.choices label img {
	cursor: pointer;
}

.modal {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: var(--modal-bg-color);
	display: flex;
	justify-content: center;
	align-items: center;
	z-index: 1000;
	cursor: pointer;
}

.modal-content {
	position: relative;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 80vw;
	height: 80vh;
}

.modal-image-container {
	position: relative;
	width: 100%;
	height: 100%;
	overflow: hidden;
}

.modal-image {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	object-fit: contain;
	opacity: 0;
	transition: opacity 0s ease, transform 0s ease;
	transform: translateX(100%);
}

.modal-image.active {
	opacity: 1;
	z-index: 1;
	transform: translateX(0);
}

.modal-image.prev {
	transform: translateX(-100%);
}

.modal-image.next {
	transform: translateX(100%);
}

.modal-arrows {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal-arrow {
	cursor: pointer;
	pointer-events: auto;
	padding: 20px;
	background-color: rgba(0, 0, 0, 0.25);
	border-radius: 50%;
	transition: transform 0.1s ease, background-color 0.1s ease;
	overflow: visible;
}

.left-arrow {
	transform: rotate(225deg);
	margin-left: -30px;
}

.left-arrow:hover {
	transform: scale(1.1) rotate(225deg);
	background-color: rgba(0, 0, 0, 0.7);
}

.right-arrow {
	transform: rotate(45deg);
	margin-right: -30px;
}

.right-arrow:hover {
	transform: scale(1.1) rotate(45deg);
	background-color: rgba(0, 0, 0, 0.7);
}

@keyframes pulse {
	0% {
		box-shadow: 0 0 0 0 rgba(0, 255, 0, 0.7);
		transform: scale(1);
	}
	50% {
		box-shadow: 0 0 30px 10px rgba(0, 255, 0, 0.7);
		transform: scale(1.05);
	}
	100% {
		box-shadow: 0 0 0 0 rgba(0, 255, 0, 0);
		transform: scale(1);
	}
}

@keyframes sparkle {
	0%,
	100% {
		opacity: 0;
	}
	50% {
		opacity: 1;
	}
}

@media (max-width: 1200px) {
	.update {
		font-size: 16px;
	}

	.board-name h1 {
		font-size: 1.5rem;
	}

	.choices {
		flex-direction: column;
		align-items: center;
	}

	.choice {
		width: min-content;
	}

	.choice img.display {
		aspect-ratio: 4 / 3;
	}

	.modal-content {
		width: 95vw;
		height: 95vh;
	}

	.modal-arrow {
		padding: 15px;
	}

	.left-arrow {
		margin-left: -20px;
	}

	.right-arrow {
		margin-right: -20px;
	}
}
