:root {
	--bg-color: #ffffff;
	--text-color: #000000;
	--text-color-secondary: rgba(0, 0, 0, 0.5);
	--header-bg-color: #ffffff;
	--hover-color: #a61508;
	--hover-bg-color: #e7e7e7;
	--border-color: #000000;
	--input-bg-color: #e7e7e7;
	--toggle-bg-color: #ccc;
	--toggle-indicator-color: #ffffff;
	--toggle-active-bg-color: #2196f3;
	--tooltip-bg-color: #ffffff;
	--tooltip-text-color: #000000;
	--tooltip-shadow-color: rgba(0, 0, 0, 0.25);
	--modal-bg-color: rgba(0, 0, 0, 0.7);
	--modal-arrow-bg-color: rgba(0, 0, 0, 0.5);
	--modal-arrow-hover-bg-color: rgba(0, 0, 0, 0.7);
	--img-bg-color: rgba(0, 0, 0, 0.7);
	--box-shadow: rgba(0, 0, 0, 25%) 0px 0px 24px;
}

.dark-mode {
	--bg-color: #1a1a1a;
	--text-color: #ffffff;
	--text-color-secondary: rgba(255, 255, 255, 0.5);
	--header-bg-color: #1a1a1a;
	--hover-color: #ff6b6b;
	--hover-bg-color: #3a3a3a;
	--border-color: #ffffff;
	--input-bg-color: #3a3a3a;
	--toggle-bg-color: #555;
	--toggle-indicator-color: #1a1a1a;
	--toggle-active-bg-color: #4caf50;
	--tooltip-bg-color: #3a3a3a;
	--tooltip-text-color: #ffffff;
	--tooltip-shadow-color: rgba(255, 255, 255, 0.25);
	--modal-bg-color: rgba(0, 0, 0, 0.9);
	--modal-arrow-bg-color: rgba(255, 255, 255, 0.3);
	--modal-arrow-hover-bg-color: rgba(255, 255, 255, 0.5);
	--img-bg-color: rgba(255, 255, 255, 0.1);
	--box-shadow: rgba(255, 255, 255, 15%) 0px 0px 24px;
}

body {
	background-color: var(--bg-color);
	color: var(--text-color);
	transition: background-color 0s, color 0s;
}

.App {
	display: flex;
	flex-direction: column;
	align-items: center;
	min-height: 100vh;
	transition: background-color 0s, color 0s;
}

.header {
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
	max-width: 75rem;
	padding: 3.125rem 1.25rem 5rem 1.25rem;
	box-sizing: border-box;
	background-color: var(--header-bg-color);
	transition: background-color 0s, color 0s;
}

.nav-content {
	display: flex;
	justify-content: space-between;
	align-items: center;
	width: 100%;
}

.header h1,
.header h1 a {
	font-family: "GT Planar";
	font-weight: 900;
	font-size: 5.5rem;
	text-decoration: none;
	color: var(--text-color);
	margin: 0;
}

.header hr {
	border: 0.03125rem solid var(--border-color);
	width: 100%;
}

.links {
	display: flex;
	justify-content: space-between;
	gap: 2.5rem;
	margin: 1.25rem 0;
}

.links a {
	color: var(--text-color);
	text-decoration: none;
	font-size: 1.25rem;
	transition: transform 0.1s ease-in, color 0s;
}

.links a:hover {
	-webkit-transform: skewX(-20deg);
	-moz-transform: skewX(-20deg);
	-o-transform: skewX(-20deg);
	transform: skewX(-20deg);
	color: var(--hover-color);
}

@media (max-width: 48rem) {
	.header h1,
	.header h1 a {
		font-size: 4rem;
	}

	.links a {
		font-size: 1rem;
	}
}
