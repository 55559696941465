.search-filter {
	width: 200px !important;
	margin: 20px 0px;
}

.search-filter input {
	font-family: "GT Planar";
	font-size: 20px;
	outline: none;
	color: var(--bg-color);
}

.filter {
	width: 95%;
	background-color: var(--input-bg-color);
	border-radius: 5px;
	border-style: none;
	padding: 5px 10px;
	font-size: 15px;
}
