.dark-mode-toggle {
	position: absolute;
	top: 20px;
	right: 20px;
	width: 60px;
	height: 30px;
}

.dark-mode-toggle input {
	opacity: 0;
	width: 0;
	height: 0;
}

.dark-mode-toggle label {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: #ccc;
	cursor: pointer;
	border-radius: 30px;
	transition: 0.4s;
}

.toggle-track {
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 5px;
	border-radius: 30px;
}

.toggle-indicator {
	position: absolute;
	width: 26px;
	height: 26px;
	background-color: white;
	border-radius: 50%;
	transition: 0.4s;
	display: flex;
	align-items: center;
	justify-content: center;
	font-size: 16px;
}

input:checked + label .toggle-indicator {
	transform: translateX(25px);
}

input:checked + label {
	background-color: #1a1a1a;
}

.dark-mode .dark-mode-toggle label {
	background-color: #555;
}

.dark-mode .dark-mode-toggle input:checked + label {
	background-color: #555;
}
